Vue.component("hero-slider-component", {
    data: function () {
        return {
            heroSlider: null,
            heroSliderNav: null
        }
    },
    mounted: function () {
        this.initializeHeroSlider();
        this.initializeHeroSliderNav();
    },
    methods: {
        initializeHeroSlider: function(){
            let that = this;
            let elem = document.querySelector(".hero-slider");
            if (elem) {
                this.heroSlider = new Flickity(elem, {
                    contain: true,
                    pageDots: false,
                    imagesLoaded: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    adaptiveHeight: true,
                    slides: 3,
                    lazyLoad: 1,
                    on: {
                        change: function (index) {
                            that.setSiblings(index);
                        },
                        ready: function (index) {
                            that.setSiblings(0);
                        }
                    }
                });
            }
        },

        setSiblings: function(i){
            let total = document.querySelectorAll('.hero-slider__slide').length - 1;
            let selected = document.querySelector(".hero-slider .is-selected");

            // eerst alle post en pre classes verwijderen
            if(document.querySelector('.hero-slider__slide--pre') !== null){
                document.querySelector('.hero-slider__slide--pre').classList.remove('hero-slider__slide--pre');
            }
            if(document.querySelector('.hero-slider__slide--post') !== null){
                document.querySelector('.hero-slider__slide--post').classList.remove('hero-slider__slide--post');
            }

            // dan post en pre classes plaatsen
            if (i == 0){
                document.querySelector('.hero-slider .flickity-slider').lastChild.classList.add('hero-slider__slide--post');
                selected.nextElementSibling.classList.add('hero-slider__slide--pre');
            } else if (i == total){
                document.querySelector('.hero-slider .flickity-slider').firstChild.classList.add('hero-slider__slide--pre');
                selected.previousElementSibling.classList.add('hero-slider__slide--post');
            } else {
                selected.nextElementSibling.classList.add('hero-slider__slide--pre');
                selected.previousElementSibling.classList.add('hero-slider__slide--post');
            }
        },

        initializeHeroSliderNav: function () {
            let elem = document.querySelector(".hero-navigation");
            if (elem) {
                this.heroSliderNav = new Flickity(elem, {
                    contain: true,
                    pageDots: false,
                    imagesLoaded: true,
                    wrapAround: true,
                    prevNextButtons: false,
                    asNavFor: '.hero-slider'
                });
            }
        },
        nextHeroSlide: function() {
            this.heroSlider.next(true, false);
        },
        prevHeroSlide: function() {
            this.heroSlider.previous(true, false);
        }
    },
});
