Vue.component('upload-component', {
    data: function () {
        return {
            attachments: [],
        }
    },
    methods: {
        removeAttachment(attachment) {
            this.attachments.splice(this.attachments.indexOf(attachment), 1);
            this.$root.formFiles[this.$el.dataset.name] = this.attachments;
            this.$el.querySelector('input[name="attachments[' + this.$el.dataset.name + '][]"]').value = [];
        },

        // This function will be called every time you add a file
        uploadFieldChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (! files.length)
                return;
            for (let i = files.length - 1; i >= 0; i--) {
                this.attachments.push(files[i]);
            }

            this.$root.formFiles[this.$el.dataset.name] = this.attachments;
        },
    },

    mounted: function () {
        this.$root.formFiles[this.$el.dataset.name] = [];
    }
});
