Vue.component("hero-component", {
    data: function () {
        return {
            id: 'hero-video'
        }
    },
    mounted: function () {
        this.toggleHeroVideo();
    },
    methods: {
        toggleHeroVideo: function(){
            let elm = document.querySelector('.hero__video video');
            if (elm) {
                let setup = { controls: true, autoplay: false, preload: "auto", muted: true, loop: true };
                let player = videojs(elm, setup, function(){});
                let buttons = document.querySelectorAll('.hero__video-trigger');

                for (var buttonI = 0; buttonI < buttons.length; buttonI++) {
                    buttons[buttonI].addEventListener('click', function (e) {
                        if(!player.isFullscreen()){
                            player.requestFullscreen();
                            player.play();
                        }
                    });
                }

                player.on('fullscreenchange', function () {
                    let fullscreen = player.isFullscreen();

                    if (fullscreen) {
                        player.currentTime(0.1);
                        player.play();
                        player.controls();
                        player.muted(false);
                    } else {
                        player.muted(true);
                        player.pause();
                    }
                });
            }
        }
    },
});
